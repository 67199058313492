import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import { Observable, startWith, map } from 'rxjs';
import { Item, SelectSearch } from '../../models/select-search.model';



@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
})
export class SelectSearchComponent implements OnInit {
  items = new UntypedFormControl([]);
  filteredItems!: Observable<Item[]>;
  searchCtrl = new UntypedFormControl('');
  conditionType = SelectSearch.CONDITION;
  @Input() itemsList: Item[] = [];
  @Input() type: SelectSearch;
  @Output() itemsSelected: EventEmitter<string[] | undefined> =
    new EventEmitter<string[] | undefined>();

  ngOnInit(): void {
    this.filteredItems = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '', this.itemsList))
    );
  }

  private _filter(value: string, options: Item[]): Item[] {
    const filterValue = value.toLowerCase();
    return options.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  onSelectionChange(a: MatOptionSelectionChange): void {
    let value: Item[] = this.items?.value || ([] as Item[]);
    if (a.isUserInput) {
      if (!a.source.selected) {
        value = [];
      } else if (a.source.selected) {
        value = this.itemsList.filter((v) => v.id === a.source.value);
      }
      this.items.setValue(value);

      const selectedIds = value.length
        ? value.map((item) => item.id)
        : undefined;
      this.itemsSelected.emit(selectedIds);
    }
  }

  onKeydown(e: KeyboardEvent, i: HTMLInputElement): void {
    i.onkeydown?.(e);
    e.stopPropagation();
  }
}
